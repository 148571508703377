<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
body {
  margin: 0;
  background-color: #edf2ef;
  color: #212738;
  margin-top: 60px;
}
</style>
