
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  computed,
  watchEffect,
} from 'vue'

interface Question {
  id: number
  definition: string
  answer: string
}

export default defineComponent({
  name: 'Home',
  setup() {
    const puzzle = ref({
      questions: [
        {
          id: 1,
          definition:
            'To receive or take in the sense of letters, espeically by sight.',
          answer: 'read',
        },
        {
          id: 2,
          definition: 'To defy or challenge someone to do something.',
          answer: 'dare',
        },
        {
          id: 3,
          definition: 'Regarded with deep affection.',
          answer: 'dear',
        },
      ] as Question[],
      letters: 'ared',
    })
    const correctDisplayWordList: Ref<string[]> = ref([
      'Awesome',
      'Nice',
      'Good Job',
      'Well Done',
    ])
    const correctWord: Ref<string | null> = ref(null)
    let guesses: Ref<{ index: number; letter: string }[]> = ref([])
    let currentQuestionId: Ref<number | null> = ref(null)
    let answeredCorrectly: Ref<boolean> = ref(false)
    let currentScore: Ref<number> = ref(0)
    let incorrectGuess: Ref<boolean> = ref(false)

    const goToNextQuestion = () => {
      if (!currentQuestionId.value) {
        currentQuestionId.value = 1
        return
      }

      currentQuestionId.value++
    }

    const reset = () => {
      guesses.value = []
    }

    const guessLetter = (index: number, letter: string) => {
      guesses.value.push({ index, letter })
    }

    const currentQuestion = computed(() =>
      puzzle.value.questions.find(
        (question) => question.id === currentQuestionId.value
      )
    )

    const guessedLetterIndexes = computed(() => {
      return guesses.value.map((guess) => guess.index)
    })

    const guessedLetters = computed(() => {
      return guesses.value.map((guess) => guess.letter)
    })

    const getCorrectWord = () => {
      const randomNumber = Math.floor(
        Math.random() * correctDisplayWordList.value.length
      )

      correctWord.value = correctDisplayWordList.value[randomNumber]
    }

    const testCorrect = () => {
      if (guessedLetters.value.join('') === currentQuestion.value?.answer) {
        answeredCorrectly.value = true
        getCorrectWord()
        currentScore.value += 1000

        setTimeout(() => {
          reset()
          answeredCorrectly.value = false
          goToNextQuestion()
        }, 2000)
      } else {
        incorrectGuess.value = true

        setTimeout(() => {
          incorrectGuess.value = false
          reset()
        }, 1000)
      }
    }

    watchEffect(() => {
      if (guesses.value.length === puzzle.value.letters.length) {
        testCorrect()
      }
    })

    onMounted(() => goToNextQuestion())

    return {
      puzzle,
      guessedLetterIndexes,
      guessLetter,
      reset,
      currentQuestion,
      answeredCorrectly,
      currentScore,
      guessedLetters,
      currentQuestionId,
      correctWord,
      incorrectGuess,
    }
  },
})
